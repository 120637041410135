import React from 'react';

class Loader extends React.Component {
 
         render() {
             return (
                <div>loaderio-dafa03867ad7f94a65d12cf61eaa47c0</div>
             )
         }
              
        }

export default Loader;