import React from 'react'

class Cards extends React.Component {
    render() {
    
        return (
            <div className="card">
                Card
            </div>
        )
    }
}

export default Cards;